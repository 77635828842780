<script setup lang="ts">
withDefaults(
  defineProps<{
    title?: string | undefined;
    subtitle?: string | undefined;
    color?: "dark" | "light";
    backgroundColor?: string | undefined;
    icon?: boolean;
  }>(), {
    color: "dark",
    icon: true,
  }
);

const scrollToNexElement = (e: Event) => {
  e.preventDefault();
  e.target.scrollIntoView({ behavior: "smooth" });
};
</script>

<template>
  <div
    class="relative flex items-center justify-center flex-col min-h-[75vh] pb-10"
    :class="{}"
    :style="backgroundColor ? 'background-color:' + backgroundColor : ''"
  >
    <h1
      class="font-serifDisplay text-stage-gigantic"
      :class="{
          'text-maas-typography-headline-primary': color === 'dark',
          'text-maas-typography-headline-light': color === 'light',
        }"
    >
      {{ title }}
    </h1>
    <h3 v-if="subtitle" class="mt-10 text-h4 font-normal">{{ subtitle }}</h3>
    <a v-if="icon" href="#" class="absolute bottom-0" @click="(e) => scrollToNexElement(e)">
      <svg width="44" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M.8 1.1A1.5 1.5 0 0 1 2.9.8L22 15.125 41.1.8a1.5 1.5 0 1 1 1.8 2.4l-20 15a1.5 1.5 0 0 1-1.8 0l-20-15a1.5 1.5 0 0 1-.3-2.1Z" fill="#000"/></svg>
    </a>
  </div>
</template>
